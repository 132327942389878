import React from 'react';

import styles from './RatingTable.module.scss';

const data = [
  {
    id: 0,
    type: 'Corporate Family',
    agency: `Moody's`,
    rating: 'Caa3',
    outlook: 'Negative',
    date: 'November 27th, 2024',
  },
  {
    id: 1,
    type: '',
    agency: 'S&P Global',
    rating: 'CCC',
    outlook: 'Negative',
    date: 'February 17th, 2025',
  },
  {
    id: 2,
    type: 'Senior Secured Notes ',
    agency: `Moody's`,
    rating: 'Caa3',
    outlook: 'Negative',
    date: 'November 27th, 2024',
  },
  {
    id: 3,
    type: '',
    agency: 'S&P Global',
    rating: 'CCC',
    outlook: '',
    date: 'February 17th, 2025',
  },
];

const RatingTable = () => {
  return (
    <div className={styles.wrapper} id="rating">
      <h2>Ratings</h2>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Agency</th>
            <th>Rating</th>
            <th>Outlook</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ id, type, agency, rating, outlook, date }) => (
            <tr className="key" key={id}>
              <td>{type}</td>
              <td>{agency}</td>
              <td>{rating}</td>
              <td>{outlook}</td>
              <td>{date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RatingTable;
